.del_div{
    display: flex;
    flex-direction: column;
    gap: 20px;
    overflow-y: auto;
    height: -webkit-fill-available;
    margin-bottom: 120px;
    padding: 30px;
    align-items: center;
    overflow: hidden;
}

.del_h1{
text-align: center;
direction: rtl;
float: none;
margin: 0;
margin-top: 20%;
font-size: x-large;
}

.del_h2{
    text-align: center;
    direction: rtl;
    float: none;
    }

.del{
    padding: 5px;
    color: white;
    outline: none;
    border: #333 solid 1px;
    border-radius: 5px;
    background-color: #c62128;
    background-image: linear-gradient(147deg, #c62128 0%, #a00000 74%);
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1), 0 3px 10px 0 rgba(0, 0, 0, 0.1);
    cursor: pointer;
    display: flex;
    justify-content: center;
    font-size: medium;
    width: -webkit-fill-available;
    width: fill-available;
    height: -moz-fit-content;
    height: fit-content;
}

.confirmation-dialog{
margin-top: 30px;
background-color: aliceblue;
border-radius: 15px;
padding: 20px;
}

.del2{
    padding: 5px;
    color: white;
    outline: none;
    border: #333 solid 1px;
    border-radius: 5px;
    background-color: #c62128;
    background-image: linear-gradient(147deg, #c62128 0%, #a00000 74%);
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1), 0 3px 10px 0 rgba(0, 0, 0, 0.1);
    cursor: pointer;
    display: flex;
    justify-content: center;
    font-size: medium;
    width: 45%;
    height: -moz-fit-content;
    height: fit-content;
    float: left;
}

.cancel{
    padding: 5px;
    color: #647dee;
    outline: none;
    border: #333 solid 1px;
    border-radius: 5px;
    /* background-color: #c62128;
    background-image: linear-gradient(147deg, #c62128 0%, #a00000 74%); */
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1), 0 3px 10px 0 rgba(0, 0, 0, 0.1);
    cursor: pointer;
    display: flex;
    justify-content: center;
    font-size: medium;
    width: 45%;
    height: -moz-fit-content;
    height: fit-content;
    float: right;
}

.logut{
    background-color: #fff;
    color: #647dee;
    border: 1px solid #647dee;
    border-radius: 5px;
    box-shadow: 0 2px 4px 0 rgba(0,0,0,.1), 0 1px 5px 0 rgba(0,0,0,.1);
    font-size: medium;
    padding: 5px;
    width: -moz-fit-content;
    width: fit-content;
    background-image: none;
    width: -webkit-fill-available;
}

.del_div .share-buttons h2{
    margin: 0;
    margin-right: 10px;
}