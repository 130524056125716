.mid{
justify-content: center;
align-items: center;
flex-direction: column;

top: 50%;
left: 50%;
transform: translate(-50%, -50%);
width: 980px;
height: 70%;
position: absolute;
z-index: 1;
border-radius: 2%;
display: flex;
min-height: 350px;
max-height: 700px;
background-color: white;
box-shadow: 0 3px 5px rgba(0, 0, 0, 0.2);

font-family: 'Heebo', sans-serif;
  font-weight: 400;
  text-align: right;
  font-style: normal;
}

.about_h1{
    display: flex;
    align-items: center;
    color: #333;
}

.about_h1 img{
    height: 100px;
    justify-self: center;
    display: inline-block;
    position: relative;
}

.about_h2{
font-weight: 400;
padding-left: 80px;
padding-right: 80px;
direction: rtl;
line-height: 2em;
color: #333;
margin-top: 0;
}

.about_h3{
color: #333;
}

.mid a{
all: unset;
cursor: pointer;
}

.mid a:visited,:hover, :active, :focus{
text-decoration: none;
}