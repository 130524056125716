nav {
    height: 100px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 20px;
    z-index: 1;
    width: 1080px;
    margin: 0 auto;
    position: fixed;
    top: 0;
    font-weight:600 ;
    color: white;
    left: 50%;
    transform: translate(-50%, 0);
  }
  
  /* Style the logo and links */
  nav a {
    color: white;
    text-decoration: none;
    display: inline-block;
    opacity: 0.5;
  }
  
  .active {
    font-weight: bold;
    opacity: 1;
  }

  nav ul {
    list-style: none;
    display: flex;
    justify-content: right;
    align-items:center;
  }
  
  nav li {
    margin-right:40px;
    cursor: pointer;
  }
  
  nav li:hover {
    opacity: 0.7;
  }

nav img{
height: 80px;
width: auto;
justify-self: center;
filter: brightness(0) invert(1);
display: inline-block;
position: relative;
opacity: 1;
}

li button{
border: none;
background-color:unset;
color: white;
font-weight: 600;
font-size: 1rem;
cursor: pointer;
}

.mobileMenu{
display: none;
}

.xmenu{
  display: none;
}

.traineeSignInBtn{
width: fit-content;
justify-self: center;
font-size: medium;
background-image: none;
background-color: white;
border: solid #414447 1px;
padding: 5px 20px;
box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1), 0 3px 10px 0 rgba(0, 0, 0, 0.1);
cursor: pointer;
border-radius: 5px;
display: flex;
justify-content: space-around;
gap: 5px;
}
.traineeSignInBtn img{
height: 20px;
  width: auto;

    justify-self: left;
    filter:none;
    /* display: inline-block; */
    position: relative;
    opacity: 1;
}