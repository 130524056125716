.Home{
    height: 100vh; 
}

.background{
background: linear-gradient(55deg, rgba(154,111,255,1) 0%, rgba(100,90,255,1) 50%, rgba(119,119,207,1) 100%);
width: 100%;
min-height: 400px;
display: flex;
justify-content: center;
align-items: center;
}

.x1080 {
    width: 1080px;
    margin: 0 auto;
}

.main{
top: 50%;
left: 50%;
transform: translate(-50%, -50%);
width: 980px;
/* height: 540px; */
height: 70vh;
background-color: aliceblue;
position: absolute;
z-index: 1;
border-radius: 2%;
border: solid 15px #F9F9F9;
 display: flex; /*for the inside col */
 min-height: 350px;
 max-height: 700px;
}

.col-1{
    flex-basis: 50%;
    overflow: hidden;
    border-top-left-radius: 2%;
    border-bottom-left-radius: 2%;
  }
  
.col-2 {
    background-color: white;
    flex-basis: 50%;
    overflow-y: auto;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    background-color: ghostwhite;
    border-top-right-radius: 2%;
    border-bottom-right-radius: 2%;

}

.circle{
width: 100px;
height: 100px;
border-radius: 50%;
border: rgb(119,119,207) 1px solid;
float: right;
display: flex;
position: relative;
overflow: hidden;
}

.circle img{
  position: absolute;
  height: 100%;
  width: 100%;
  object-fit: cover;
}

h1{
float: right;
margin-right: 25px;
}

p{
clear: both;
float: right;
}

h2{
clear: both;
float: right;
}

ul{
clear: both;
float: right;
}


::-webkit-scrollbar {
    width: 8px;
  }
  
  ::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 2px rgba(0, 0, 0, 0.3);
    -webkit-border-radius: 5px;
    border-radius: 5px;
  }
  
  ::-webkit-scrollbar-thumb {
    -webkit-border-radius: 5px;
    border-radius: 5px;
    background: linear-gradient(55deg, rgba(154,111,255,1) 0%, rgba(100,90,255,1) 50%, rgba(119,119,207,1) 100%);
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
  }
  
  ::-webkit-scrollbar-thumb:window-inactive {
        background: linear-gradient(55deg, rgba(154,111,255,0.5) 0%, rgba(100,90,255,0.5) 50%, rgba(119,119,207,0.5) 100%);
  }