.signinbox{
    display: flex;
    border: solid white 5px;
    border-radius: 15px;
    padding:50px 20px;
    background-color: white;
    flex-direction: column;
    width: 300px;
    top: 20%;
    position: absolute;
    height: 280px;
    justify-content: center;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.signinbox >*{
    margin-top: 10px;
    line-height: 1.5rem;
}

.signinbox input{
    padding: 5px;
    border: none;
    border-bottom: 1px solid lightgray;
    outline: none;
}

.signinbox button{
    padding: 5px;
    color: white;
    font-weight: 500;
    outline: none;
    border: #647dee solid 1px;
    text-align: center;
    border-radius: 5px;
    background-color: #7f53ac;
    background-image: linear-gradient(315deg, #7f53ac 0%, #647dee 74%);
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1), 0 3px 10px 0 rgba(0, 0, 0, 0.1);;
    cursor: pointer;
}

.signinbox .btn1{
    margin-top: 40px;
}


.signinbox button:hover{
    background-image: none;
    filter: opacity(0.3);
}

.signinbox .googsign{
    background-image: none;
    background-color: white;
    color: #414447;
    border: solid #414447 1px;
    padding: 5px 20px;
}

.signinbox .facesign{
    background-image: none;
    background-color: white;
    color: #414447;
    border: solid #414447 1px;
    padding: 5px 20px;
}

.signinbox .googsign:hover{
    background-color: whitesmoke;
}

.signinbox img{
    float: right;
    width: 20px;
    height: 20px;
}

.signinbox .signup{
    background: none;
    border: none;
    box-shadow: none;
    color: #414447;
}

span{
color: red;
align-self: center;
font-size: medium;
}

.signinbox h2{
    align-self: center;
    font-weight: 600;
}