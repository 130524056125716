.trainer_search{
display: inline;
color: #414447;
}

.filter{
padding: 16px;
grid-row-gap: 5px;
background-color: white;
flex-wrap: wrap;
flex-direction: row-reverse;
border-bottom: #414447 2px solid;
align-items: center;
display: grid;
grid-template-columns: repeat(1, 100%);
grid-template-rows: repeat(2, 1fr 1fr);
direction: rtl;
/* stick to top*/
position: sticky;
top: 0;
z-index: 1;
}

.mobile_explain{
display: none;
}
.mobile_explain_web{
    direction: rtl;
    display: flex;
    justify-content: center;
    padding: 10px;
    padding-left:10px;
    padding-right: 10px;
    background-color: #2a2a72;
    background-image: linear-gradient(315deg, #2a2a72 0%, #009ffd 74%);
    width: -webkit-fill-available;
    margin: 0 auto;
    margin-top: 10px;
    margin-bottom: 10px;
    align-self: center;
    color: white;
    box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.2), 0 3px 10px 0 rgba(0, 0, 0, 0.19);   
    font-size: large;
    text-align: center;
}

.row_label{
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: -webkit-fill-available;
    direction: ltr;
}

.AUTOCOMPLETE input{
    width: -webkit-fill-available;
    appearance: none;
    -webkit-appearance: none;
    border: none;
    border-radius: 5px;
    background-color: hsl(0, 0%, 100%);
    border-color: hsl(0, 0%, 80%);
    border-radius: 4px;
    border-style: solid;
    border-width: 1px;
    line-height: 1.5rem;
    font-size: medium;
}

select{
    margin-bottom: 10px;
    direction: rtl;
    border: none;
    border-radius: 5px;
    background-color: hsl(0, 0%, 100%);
    border-color: hsl(0, 0%, 80%);
    border-radius: 4px;
    border-style: solid;
    border-width: 1px;
    align-self: end; 
    font-size: medium;
    cursor: pointer;
}  

.filter label{
align-self: center;
font-weight: 600;
}

.filt_btn{
    font-family: 'Heebo', sans-serif;
    padding: 5px;
    border: solid 1px ;
    border-color: hsl(0, 0%, 80%);
    border-radius: 5px;
    display: block;
    width: fill-available;
    text-align: center;
    font-size: medium;
    cursor: pointer;
    border-radius: 99rem;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.1), 0 2px 2px 0 rgba(0, 0, 0, 0.1);
    padding-left: 10px;
    padding-right: 10px;
}

.filt_btn img{
height: 10px;
padding: 5px;
vertical-align: middle;
}

.checked img{
    filter: brightness(0) invert(1);
    }

.not_checked{
    color: #4d4e50c1;
    background-color: white;
}

.checked{
    background: linear-gradient(55deg, rgba(154,111,255,1) 0%, rgba(100,90,255,1) 50%, rgba(119,119,207,1) 100%);
    color: white;
    font-weight: 600;
}

.checkbox_div{
    position: relative;
    margin: 5px;
    box-sizing: border-box;
    width: 50%;
}

.search-item{
    font-size: small;
    vertical-align: top;
    width: fit-content;
  }

.searchbtn{
font-family: 'Heebo', sans-serif;
padding: 5px;
color: white;
font-weight: 500;
outline: none;
border: #647dee solid 1px;
text-align: center;
border-radius: 5px;
background-color: #7f53ac;
background-image: linear-gradient(315deg, #7f53ac 0%, #647dee 74%);
box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1), 0 3px 10px 0 rgba(0, 0, 0, 0.1);
width: 100%;
cursor: pointer;
}

.on{
width:70%;
}

.searchbtn:hover{
background-color: #647dee;
background-image: none;
filter: opacity(0.8);
}

.clearbtn{
width: 25%;
font-family: 'Heebo', sans-serif;
padding: 5px;
color: #414447;
font-weight: 500;
outline: none;
border: #414447 solid 1px;
text-align: center;
border-radius: 5px;
background: radial-gradient(circle at 18.7% 37.8%, rgb(250, 250, 250) 0%, rgb(225, 234, 238) 90%);
box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1), 0 3px 10px 0 rgba(0, 0, 0, 0.1);
cursor: pointer;    
}

.off{
display: none;
}

.trainer_box{
    height: 80px;
    background-color: white;
    border: solid #414447 0.5px;
    border-radius: 20px;
    box-shadow:  0 2px 8px 0 rgba(0, 0, 0, 0.2), 0 3px 10px 0 rgba(0, 0, 0, 0.19);
    /* flex: auto; */
    padding: 10px;
    justify-content: right;
      display: grid; 
      grid-template-columns: 75% 25%;
      grid-template-rows: 50% 50%;
      gap: 0px 0px; 
      grid-template-areas: 
        "h1 circle"
        "ul circle"; 
      width: 85%; 
      align-self: center;
      cursor: pointer;
}
.results{
margin-top:20px ;
display: flex;
flex-direction: column;
padding-bottom: 10px;
/* height: 100%; */
overflow: auto;
gap: 15px;
overflow-x: hidden;
}

.trainer_box:hover{
/* background-color: rgb(246, 246, 246); */
border: solid #44ffd9 1px
}

.trainer_box :first-child{
    grid-column: 3;
}

.trainer_box .circle{
    width: 70px;
    height: 70px;
}

.trainer_box h1{
    /* grid-column: 2; */
    font-size: 1.5rem;
    text-align: end;
    grid-area: h1;
    margin-right: 0; 
    align-self: center;
}

.trainer_box ul{
    display: flex;
    /* grid-row: 2;
    grid-column: 1; */
    list-style: none;
    grid-area: ul;
    justify-self: flex-end;
    flex-direction: row-reverse;
    align-self: center;
}

.trainer_box li{
/* padding-left:10px; */
width: max-content;
/* overflow: hidden; */
}

.trainer_box .img_t{
align-self: center;
justify-self: center;
grid-area: circle;  
}

.backbtn{
    color: rgb(100,90,255);
    font-weight: 600;
    background: none;
    border: none;
    font-size: 1rem;
    border-bottom: 2px solid rgb(100,90,255);
    text-align: right;
}

.backbtn:hover{
    font-weight:700 ;
}

.profile{
    padding: 30px;
    /*display: grid;
    justify-content: flex-end; */
    max-height: 590px;
    display: grid;
    grid-template-columns: minmax(0, auto); /* Set min width to 0 and max width to auto */
    grid-template-rows: repeat(6, minmax(0, auto)); /* Set min height to 0 and max height to auto */
    gap: 10px 10px;
    grid-auto-flow: row;
    grid-template-areas:
    "button"
    "circle"
    "p"
    "h2"
    "p2"
    "ul";  
}

.namenphoto{
    align-self: center;
    justify-self: end;
    /* margin-top: 30px; */
    grid-area: circle; 
}

.profile .circle{
    float: right;
}


.profile .trainerdesc {
    align-self: center;
    justify-self: end;

    grid-area: p;
}

.profile h2 {
    align-self: center;
    justify-self: end;
    margin: 0;
    grid-area: h2;
}

.profile .training {
    align-self: center;
    justify-self: end;
    margin: 0;
    grid-area: p2;
}
.training span{
    color: #414447;
}
.profile li img{
width: 40px;
height: 40px;
margin-left: 5px;
}

.profile ul {
    grid-area: ul;
    display: flex;
    list-style: none;
    flex-direction: row-reverse;
    justify-content: space-between;
    padding: 0;
    flex-wrap: wrap;
    text-align: right;
}

.profile li{
font-weight: 600;
width: 48%; 
height: 100px;
display: flex;
flex-direction: row-reverse;
align-items: center;
}

.profile li a{
    color: #333;
    text-decoration: none;
    font-size: small;
    font-weight: 600;
}

.no_filter{
display: none;
}
.show_filter{
display: none;
}
.map_or_list{
display: none;
}

.a_bold{
background-color: white;
padding: 5px;
border-radius: 5px;
font-size: large;
box-shadow: 0px 3px 3px -3px rgba(0, 0, 0, 0.5);
}

.more{
color: #414447;
direction: rtl;
}

.namenphoto button{
display: flex;
align-items: center;
background-color: white;
    padding: 5px;
    border-radius: 5px;
    font-size: large;
    box-shadow: 0px 3px 3px -3px rgba(0, 0, 0, 0.5);
    border: none;
    margin-right: 125px;

}
.namenphoto button img{
height: 20px;
margin-left: 10px;
}