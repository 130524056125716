.p_web button.change{
    background-color: #647dee;
    color: #fff;

    border: 1px solid #647dee;
    border-radius: 5px;
    box-shadow: 0 2px 4px 0 rgba(0,0,0,.1), 0 1px 5px 0 rgba(0,0,0,.1);
    font-size: medium;
    padding: 5px;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    background-image: none;
}

.p_web button.cancel{
    background-color: #fff;
    color: #647dee;

    border: 1px solid #647dee;
    border-radius: 5px;
    box-shadow: 0 2px 4px 0 rgba(0,0,0,.1), 0 1px 5px 0 rgba(0,0,0,.1);
    font-size: medium;
    padding: 5px;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    background-image: none;  
}

.daily.trainee_p{
gap: 8px;
}

h3.trainee_p{
    margin:0;
    font-weight: 600;
    width: fill;
    text-align: center;
    background-color: #647dee;
    color: white;
    border-radius: 5px;
}

.my_trainers{
    display: flex;
    gap: 30px;
    padding-left: 20px;
    padding-right: 20px;
}

.one_trainer{
display: flex;
flex-direction: column;
width: -webkit-fill-available;
align-items: center;

background-color: whitesmoke;
padding: 5px;
border-radius: 10px;
}

.one_trainer h3{
margin: 0;
margin-bottom: 10px;
width: 100%;
text-align: center;
}

.one_trainer .circle{
height: 60px;
width: 60px;
}

.link_p{
all: initial;
text-decoration: none;
flex-basis: 100%;
}