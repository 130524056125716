.update_profile{
top: 50%;
left: 50%;
transform: translate(-50%, -50%);
width: 980px;
height: 70%;
background-color: white;
position: absolute;
z-index: 1;
border-radius: 2%;
/* border: solid 15px #F9F9F9; */
 display: grid; 
 grid-template-columns: 1fr minmax(100px, 200px); 
 grid-template-rows: 1fr; 
 gap: 0px 0px; 
 grid-template-areas: 
   "inputs settings"; 
   min-height: 350px;
   max-height: 700px;
   overflow: auto;
box-shadow: 0 3px 5px rgba(0, 0, 0, 0.2);
}

/* .update_profile button{
    height: fit-content;
} */

.update_profile .upload_file{
    height: 3rem;
    width: 120px;
    font-size: medium;

    padding: 5px;
    color: white;
    font-weight: 500;
    outline: none;
    border: #647dee solid 1px;
    text-align: center;
    border-radius: 5px;
    background-color: #7f53ac;
    background-image: linear-gradient(315deg, #7f53ac 0%, #647dee 74%);
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1), 0 3px 10px 0 rgba(0, 0, 0, 0.1);
}
.upload_file:hover{
        background-image: none;
        filter: opacity(0.3);
}

.update_profile .hiddenbtn{
    height: inherit;
    top: 0;
    position: inherit;
    transform: translate(0, -80%);
    width: 100%;
    cursor: pointer;
    opacity: 0;
}

.settings { 
    grid-area: settings;
    padding: 30px;
    display: grid;
    grid-template-columns: 10% 90%;
    grid-template-areas: 
        "line set";
 }

.settings ul{
list-style: none;
grid-area: set;
margin-top: 50px;
text-align: right;
} 

.settings li{
margin-bottom: 30px;
color: #414447;
font-weight: 500;
font-size: larger;
}

.settings ul li:last-child {
    margin-top: 300px;
  }

.settings .chosen{
    color: #647dee;
    font-weight: 600;
    }

.settings li:hover{
    color: rgb(100,90,255);
    cursor: pointer;
}

.inputs {
     grid-area: inputs;
     display: grid;
     padding: 30px;
     grid-template-columns: 1fr 1fr;
     grid-gap: 20px;
     direction: rtl;
     }

.settings .v_line{
    width: 2px;
    height: 100%;
    background-color:#414447; ;
    grid-area: line;

}

.inputs input{
    background-color: hsl(0, 0%, 100%);
    border-color: hsl(0, 0%, 80%);
    border-radius: 4px;
    border-style: solid;
    border-width: 1px;
    padding: 6px;

    padding-right: 10px;
    height: 2rem;
    align-self: center;
    font-size: larger;
    width: -webkit-fill-available;
}

.AUTOCOMPLETE{
    align-self: center;
}
.AUTOCOMPLETE input{
    text-align: right;
}

.inputs textarea{
    background-color: hsl(0, 0%, 100%);
    border-color: hsl(0, 0%, 80%);
    border-radius: 4px;
    border-style: solid;
    border-width: 1px;
    padding: 6px;
min-height: 70px;
padding-right: 10px;
grid-column: 1/3;
font-size: larger;
max-width: 706px;
max-height: 300px;
}
.profilebtn{
    padding: 5px;
    color: white;
    outline: none;
    border: #647dee solid 1px;
    border-radius: 5px;
    background-color: #7f53ac;
    background-image: linear-gradient(315deg, #7f53ac 0%, #647dee 74%);
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1), 0 3px 10px 0 rgba(0, 0, 0, 0.1);
    cursor: pointer;
    display: flex;
    justify-content: center;
    font-size: medium;
    width: fill-available;
    height: fit-content;
}
.profilebtn:hover{
    background-image: none;
    filter: opacity(0.3);
}
.customSelect{
    align-self: center;
    font-size: larger;
    width: -webkit-fill-available;
}

.img_t{
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.box{
    height: 150px;
    border: rgb(119,119,207) 1px solid;
    float: right;
    display: flex;
    position: relative;
    overflow: hidden;
}

.p_web{
    display: flex;
    flex-direction: column;
    gap: 10px;
    overflow-y: auto;
    /* height: inherit; */
    height: -webkit-fill-available;
    margin-bottom: 120px;
    padding: 30px;
}
.p_web h2{
text-align: center;
margin-top: 0;
}
.p_web input{
    font-size: 16px;
    appearance: none;
    -webkit-appearance: none;
    border: none;
    background-color: hsl(0, 0%, 100%);
    border-color: hsl(0, 0%, 80%);
    border-radius: 4px;
    border-style: solid;
    border-width: 1px;
    padding: 2px;
    text-align: left;    
    height: 1.5rem;
    padding-left: 10px;
    width: -webkit-fill-available;
}
.p_web button{
    padding: 5px;
    color: white;
    outline: none;
    border: #647dee solid 1px;
    border-radius: 5px;
    background-color: #7f53ac;
    background-image: linear-gradient(315deg, #7f53ac 0%, #647dee 74%);
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1), 0 3px 10px 0 rgba(0, 0, 0, 0.1);
    cursor: pointer;
    display: flex;
    justify-content: center;
    font-size: medium;
    width: -webkit-fill-available;
    width: fill-available;
    height: -moz-fit-content;
    height: fit-content;
}
.p_web button.taken{
background-color: white;
background-image: none;
color: #414447;
}

.p_web button a{
text-decoration: none;
color: white;
}
.p_web p{
text-align: center;
}
.p_web .url_add{
display: flex;
flex-direction: row-reverse;
align-items: center;
}
.p_web h3{
width: 45%;
text-align: right;
margin-right: 10px;
}
.update_trainee_btn{
margin-top: 30px;
padding:5px;
color: #647dee;;
background: white;
border: 1px solid #647dee;
border-radius: 5px;
width: -webkit-fill-available;
font-size: medium;
}