.btns_top{
display: flex;
}
.p_web .btn_top{
box-shadow: none;
border-color: #33333372;
border-radius: 0;
color: #3333337b;
background-color: whitesmoke;
background-image: none;
}

.fixed-hours span{
color: #647dee;
font-size: small;
font-weight: 600;
display: flex;
margin-right: 20px;
}

.calendar.p_web span{
    color: #647dee;
    font-size: medium;
    font-weight: 600;
    display: flex;
    width: fit-content;
    border: 1px solid #647dee;
    border-radius: 5px;
    padding: 5px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1), 0 1px 5px 0 rgba(0, 0, 0, 0.1);
    }
    .calendar.p_web span img{
        margin-left: 10px;
       height: 20px; 
    }
    .calendar.p_web span.done{
    background-color: #1ee954;
    color: white;
    border: none;   
    }
    .calendar.p_web span.approved{
    background-color: #647dee;
    color: white;
    }
    .calendar.p_web span.declined{
    color: crimson;
    border: none;
    }
    .calendar.p_web span.changed{
    background-color: #1047eb;
    color: white;
    border: none; 
    }
    .calendar.p_web .daily span.x_session{
    color: #ff726f;
    border-color: #ff726f;
    margin-right: 5px;
    float: left;
    }
    .calendar.p_web .request.approved span.x_session{
    background-color: rgb(255, 255, 255);
    }

.calendar.p_web span.saved{
color: white;
background-color: #333;
border-radius: 5px;
font-size: large;
width: 50%;
justify-content: center;
padding: 5px;
margin-top: 10px;
margin-bottom: 10px;
border:none;
}

.p_web .btn_top.selected{
box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.2);
background-color: white;
color: #333;
font-weight: 600;
}


.day{
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 5px;
    font-weight: 600;
}

.day.selected{
    border-radius: 50%;
    background-color: whitesmoke;
}
.day.selected .day-name{
    color: #647dee;
    font-weight: 600;
}

.time_q{
display: flex;
justify-content: start;
align-items: center;
width: -webkit-fill-available;
}

.react-select-container{
z-index: 101;
} 

.react-select-container input{
display: none;
}

.calendar.p_web .time_q span{
visibility: hidden;
display: none;
}

.time_q h3{
    width:70%;
    margin-right:0;
    font-size: medium;
    }
.time_q input{
width: fit-content;
max-width: 40px;
text-align: center;
padding-left: 0;
}
.time_q label{
margin-right: 5px;
}

.pluss{
    display: flex;
    justify-content: center;
    gap: 8px;
    align-items: center;
    height: fit-content;
    padding: 10px;
    border-top: 1px #3333333c solid;
}
.pluss h5{
margin: 0;
}

.hours{
display: flex;
justify-content: space-around;
align-items: center;
gap: 30px;
height: fit-content;
}

.hours_limit{
    display: flex;
    justify-content: space-around;
    align-items: center;
    gap: 30px;
    border-top: 1px #3333333c solid;
    background-color: whitesmoke;
    height: fit-content;
    padding: 5px;
}

.hours_limit h3{
    margin-right:0;
    font-size: medium;
    width: fit-content;
}

.hours_limit select{
    border-radius: 0;
    border: none;
    border-bottom: 1px solid #33333342;
    width: 85px;
    font-size: large;
    appearance: none;
    -webkit-appearance: none;
    background-color: white;
    color: #333;
}

button.plus, button.minus{
border-radius: 50%;
height: 30px;
width: 30px;
display: flex;
justify-content: center;
align-items: center;
font-size: larger;
}

button.minus{
border: none;
background-image: none;
background-color: #333;
}


/* requests */
.request{
    display: flex;
    flex-direction: column;
    padding: 30px;
    padding-top: 15px;
    padding-bottom: 15px;
    background-color: white;
    border-radius: 10px;
    width: 75%;
    align-self: center;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1), 0 3px 10px 0 rgba(0, 0, 0, 0.1);
    gap: 8px;
}

.request h2{
    text-align: right;
    margin-bottom: 5px;
}
.request h3{
margin: 0;
}

.request .hours{
width: fit-content;
gap: 10px;
}
.request .hours h3{
    text-align: right;
    margin: 0;
    width: fit-content;
}

.request .hours a{
text-decoration: none;
color: #263c9eb0;
}

.calendar.p_web .request .more, 
.calendar.p_web span.more{
    box-shadow: none;
border: none;
color: #636363;
align-self: self-start; 
font-size: medium;
padding-right: 0;
}


.request .btns{
    display: flex;
    gap: 15px;
}

.request .btns .approve{
background-image: none;
background-color: aquamarine;
color: green;
border: none;
}
.request .btns .decline{
    background-image: none;
    background-color: firebrick;
    color: whitesmoke;
    border: none; 
}

.request.approved{
margin-top: 10px;
background-color: whitesmoke;
opacity: 0.9;
}

.request.approved span{
align-self: flex-start;
}

/* daliy */
.p_web .view_btns{
display: flex;
gap: 10px;
justify-content: space-evenly;
}
.goog_switch_container, .p_web button.week_month_view{
color: #333;
background-image: none;
background-color: whitesmoke;
border: none;
font-weight: bold;
box-shadow: none;
height: 2.5em;
display: flex;
justify-content: center;
align-items: center;
width: fit-content;
}
.p_web button.week_month_view.active-view{
background-color: #333;
color: white;
}
.p_web .view_btns img{
height: 30px;
margin-left: 10px;
}
.p_web button.google_calendar{
padding-left: 20px;
padding-right: 20px;
}

.p_web button.toogle{
    background-color: #f00;
    background-image: none;
    border: none;
    color: white;
    padding: 10px 20px;
    text-align: center;
    font-size: 16px;
    transition: background-color 0.3s;
    cursor: pointer;
    outline: none;
  }

.daily{
    position: relative;
    display: flex;
    flex-direction: column;
    padding: 30px;
    padding-top: 10px;
    padding-bottom:10px;
    background-color: white;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    width: 80%;
    height: fit-content;
    border-right:#636363 solid 5px;
    align-self: center;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1), 0 3px 10px 0 rgba(0, 0, 0, 0.1);
    margin-top: 10px;
}
.daily.now{
border-right: #647dee solid 5px;
}
.daily.past{
border-right:rgb(179, 179, 179) solid 5px;
opacity: 0.5;
}
.daily h3{
margin: 0;
width: fit-content;
}
.daily .hours{
width: fit-content;
gap: 10px;
text-align: right;
align-items: center;
}
.daily span{
color: #263c9e;
float: right;
}

.daily .circle{
    height: 60px;
    width: 60px;
    position: absolute;
    left: 0;
    top:0;

    margin-left: 10px;
    margin-top: 10px;
}

.no-requests{
display: flex;
justify-content: center;
flex-direction: column;
align-items: center;
}
.no-requests h3{
    width: fit-content;
    margin: 0;
}

.filter-buttons{
display: flex;
gap: 10px;
}

.filter-buttons button{
    box-shadow: none;
    border-color: #33333372;
    border-radius: 0;
    color: #3333337b;
    background-color: whitesmoke;
    background-image: none;
    border-radius: 10px;
}

.filter-buttons button.selected{
    box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1);
    background-color: white;
    color: #333;
    font-weight: 600;   
}

.p_web .if_holiday{
display: flex;
gap: 10px;
margin-right: 10px;
}
.p_web input.holiday{
    font-size: 16px; 
    appearance: auto; 
    -webkit-appearance: auto;  
    height: 1.5rem; 
    width: 20px;
    margin-left: 5px;
}

