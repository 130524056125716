.map-container{
 width: 100%;
 height: 100%;   
}

.map-container .trainer_box{
position: absolute;
bottom:5%;
left: 5%;

background: white;
background: linear-gradient(to right bottom, rgba(255, 255, 255, 1), rgba(255,255,255,0.6));
}