/* .session-confirmation {
    padding: 20px;
    background-color: #dff0d8;
    border: 1px solid #d6e9c6;
    border-radius: 4px;
    color: #3c763d;
    max-width: 400px;
    margin: 0 auto;
  }
  
  .session-confirmation h2 {
    margin-top: 0;
  }
   */

   .session-confirmation {
    padding: 20px;
    width: 80%;
    height: fit-content;
    margin-top: 15%;
    border-radius: 15px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border: 1px solid #33333332;
    background-color: white;
    box-shadow: 0 3px 5px rgba(0, 0, 0, 0.2);
    box-sizing: border-box;
    position: absolute;
    top: var(--top_space);
    left: 50%;
    transform: translateX(-50%);
  }
  
  .session-confirmation h2 {
    margin-top: 0;
    direction: rtl;
    text-align: center;
  }

  .session-confirmation h3{
    direction: rtl;
    margin-bottom: 0;
  }

  .session-confirmation .time{
  display: flex;
  gap: 15px;
  justify-content: center;
  align-items: center;
  font-size: large;
  }

  .session-confirmation p{
  margin-top: 5px;
  direction: rtl;
  }
  .session-confirmation p span{
  color: rgba(100,90,255,1);
  font-weight: 600;
  }
  .view_my_sessions {
    display: flex;
    position: absolute;
    left: 50%;
    top: 70%;
    transform: translate(-50%, -50%);
    background-color: white;
    border-radius: 10px;
}

  