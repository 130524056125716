.blog{
    color: #333;    
    top: 150px;
    left: 50%;
    transform: translate(-50%, 0);
    width: 980px;
    /* height: 70vh; */
    background-color: white;
    position: absolute;
    z-index: 1;
    border-radius: 15px;
    display: flex;
    min-height: 350px;
    max-height: 70vh;
    box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.2), 0 3px 10px 0 rgba(0, 0, 0, 0.19);
    /* overflow-y: auto; */
    padding-bottom: 20px;
    padding-top: 20px;
}

.blog_cho{
display: flex;
flex-direction: column;
align-items: center;
position: sticky;
top: 0;
}

.blog_list{
    list-style:none;
    margin-top: 20px;
}

.blog_list li{
    height: 100px;
    width: 200px;
    background-color: white;
    margin-bottom: 20px;
    padding: 10px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.2), 0 3px 10px 0 rgba(0, 0, 0, 0.19);
    border-radius: 15px;
}

.blog_list li:hover{
background-color: whitesmoke;
}

.blog_list li a{
text-decoration: none;
color: #333;
display: flex;
flex-direction: column;
}

.blog_list li a:visited,
.blog_list li a:focus,
.blog_list li a:hover,
.blog_list li a:active {
  color: #333; /* Set the same color for visited, focused, hovered, and active states */
}

/* arct */
.arct {
    padding: 30px;
    padding-left: 50px;
    padding-top: 10px;
    width: -webkit-fill-available;
    display: flex;
    flex-direction: column;
    overflow: auto;
    height:auto;
    border-right: solid 1px lightgray;
    margin-right: 20px;
  }
  
  .arct h2 {
    font-size: xx-large;
    margin-top: 0;
  }
  
  .arct h3 {
    font-size: medium;
    color: lightslategray;
    margin: 0;
  }
  
  .arct p {
    font-size: larger;
    padding-bottom: 10px;
  }
  