:root {
  --primary-color: rgba(154, 111, 255, 1);
  --secondary-color: rgba(100, 90, 255, 1);
  --accent-color: rgba(119, 119, 207, 1);
  --text-color: #333;
  --background-color: lightsteelblue;
  --border-color: rgb(255, 255, 255);
  --dark-color: aliceblue;
  --image-border-color: lightsteelblue;
}

.back_color{
  height: 100vh;
  background-color: #b8c6db;
  background-image: linear-gradient(315deg, #b8c6db 0%, #f5f7fa 74%);
  background-color: #e5e5f7;
opacity: 0.8;
background-image:  linear-gradient(30deg, #444cf7 12%, transparent 12.5%, transparent 87%, #444cf7 87.5%, #444cf7), linear-gradient(150deg, #444cf7 12%, transparent 12.5%, transparent 87%, #444cf7 87.5%, #444cf7), linear-gradient(30deg, #444cf7 12%, transparent 12.5%, transparent 87%, #444cf7 87.5%, #444cf7), linear-gradient(150deg, #444cf7 12%, transparent 12.5%, transparent 87%, #444cf7 87.5%, #444cf7), linear-gradient(60deg, #444cf777 25%, transparent 25.5%, transparent 75%, #444cf777 75%, #444cf777), linear-gradient(60deg, #444cf777 25%, transparent 25.5%, transparent 75%, #444cf777 75%, #444cf777);
background-size: 38px 67px;
background-position: 0 0, 0 0, 19px 33px, 19px 33px, 0 0, 19px 33px;
}

.bod {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  width: 100%;
  overflow: auto;
  height: 100%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  position: absolute;
  background: linear-gradient(55deg, var(--primary-color) 0%, var(--secondary-color) 50%, var(--accent-color) 100%);
  color: var(--text-color);
  max-width: 1080px;
  box-shadow: 0px 0px 5px 5px rgba(0, 0, 0, 0.2);
}

.bod .edit_page{
height: 30px;
width: 30px;
position: absolute;
top: 3%;
right: 3%;
z-index: 1;
font-size: small;
font-weight: bold;
line-height: 1em;
color: white;
text-decoration: none;
}

.bod .edit_page img{
  height: 30px;
  width: 30px;
}

.img_container{
  background-color: #5078f2;
  background-image: linear-gradient(120deg, #5078f2 0%, #8eb2e4 70%);
  
width: 100%;
max-height:400px;
display: flex;
justify-content: center;
position: relative;
}

.back_img{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  /* opacity: 0.9;
  filter: blur(1px); */
}

.circle_p {
  border-radius: 50%;
  border: var(--border-color) 8px solid;
  display: flex;
  position: relative;
  overflow: hidden;
  height: 200px;
  width: 200px;
  margin-top: 20%;
}

.circle_p img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.bod h1{
font-weight: 600;
}

.Trainer_name {
  float: none;
  text-align: center;
  width: 60%;
  background-color: var(--dark-color);
  padding: 15px;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  text-align: center;
  border-bottom: 1px solid #333;
  margin-right: 0;
  margin-left: 0;
  margin-bottom: 0;
  margin-top: 5%;
}

.training_types span {
  color: var(--text-color);
}

.uls {
  display: grid;
  grid-template-columns: 1fr 1fr;
  /* grid-template-rows: 1fr 1fr; */
  list-style: none;
  justify-self: center;
  justify-items: center;
  width: 60%;
  margin: 0;
  padding: 0;
  padding-bottom: 10px;
  padding-top: 10px;
  align-items: baseline;
  margin-top: 5%;

  background-color: var(--dark-color);
  border-radius: 15px;
}

li .image-container {
  display: flex;
  align-items: center;
  justify-content: center;
  /* width: 200px; */
  margin-top: 15%;
  cursor: pointer;
}

li .image-container img {
  height: 100px;
  width: 100px;
}

li .text-container {
  margin-top: 10px;
  text-align: center;
  font-weight: 400;
  font-size: large;
  color: var(--text-color);
}

 .uls li a {
  color: var(--text-color);
  text-decoration: none;
  font-size: large;
}

.powered {
  display: flex;
  align-items: center;
  font-style: italic;
  font-size: small;
  bottom: 0%;
  padding-bottom: 50px;
  color: var(--dark-color);
}

p img {
  height: 40px;
  justify-self: center;
  filter: brightness(0) invert(1);
  display: inline-block;
  position: relative;
}

.bod .trainerdesc {
  margin: 0;
  width: 60%;
  background-color: var(--dark-color);
  padding: 15px;
  text-align: center;
  border-bottom: 1px solid #333;
}

.bod h2 {
  margin: 0;
  width: 60%;
  background-color: var(--dark-color);
  padding: 15px;
  text-align: center;
  margin-bottom: -10px;
  font-weight: 600;
}

.training_types {
  margin: 0;
  width: 60%;
  background-color: var(--dark-color);
  padding: 15px;
  text-align: center;
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
  padding-bottom: 20px;
  box-shadow: 0px 10px 10px -10px rgba(0, 0, 0, 0.5);
}

.share-buttons{
display: flex;
align-items: center;
margin-top: 30px;
flex-wrap: wrap;
}
.share-buttons img{
height: 30px;
margin-right: 10px;
}
.share-buttons p{
color: white;
margin: 0;
}
.copy-success{
background-color: #333;
border-radius: 4px;
padding: 3px;
}

.sced_session{
    border-radius: 10px;
    box-shadow: 0 4px 8px 0 rgba(0,0,0,.1), 0 3px 10px 0 rgba(0,0,0,.1);
    cursor: pointer;
    font-weight: 500;
    outline: none;
    text-align: center;
    margin-top: 5%;
    background-color: #fff;
    background-image: none;
    border: none;
    color: #414447;
    padding: 5px 20px;
    font-size: large;
}

.sced_session img{
height: 20px;
width: 20px;
vertical-align: middle;
margin-left: 10px;
}