:root {
  --top_space: 85px;
}

@media (max-width: 768px) {
  .Home{
    width:auto;
    height: fit-content;
  }
  .background{
  display: none;
  }
  /* nav */
  nav {
    padding: 0;
    justify-content: center;
    color: #333;
    align-items: unset;
    display: grid;
    z-index: 2;
    height: 50%;
    height: auto;
    background: linear-gradient(55deg, rgba(154,111,255,1) 0%, rgba(100,90,255,1) 50%, rgba(119,119,207,1) 100%);
    width: 100vw;
  }
  nav.hidden {
    transform: translateY(-100%);
  }

  .mobileMenu{
  display: flex;
  position: absolute;
  right: 15px;
  top: 30px;
  border: none;
  background: none;
  flex-direction: column;
  gap: 5px;
  flex-wrap: wrap;
  align-items: flex-end;
  }

.fri1{
width: 20px;
height: 2px;
background-color: white;
transition: 1s;
}
.fri2{
  width: 15px;
  height: 2px;
  background-color: white;
  transition: 1s;
  }
  .fri3{
    width: 25px;
    height: 2px;
    background-color: white;
    }

.xmenu{
  display: block;
  position: absolute;
  right: 15px;
  top: 30px;
  border: none;
  background: none;
}

.xmenu .fri1{
rotate: 45deg;
width: 25px;
}
.xmenu .fri2{
  rotate: -45deg;
  width: 25px;
  }
  .xmenu .fri3{
    display: none;
    }

  .x1080{
    display: flex;
    flex-direction: column;
    background: 
    linear-gradient(55deg, rgba(154,111,255,1) 0%,
    rgba(100,90,255,1) 50%,
    rgba(119,119,207,1) 100%);
    gap: 25px;
    /* margin: unset; */
    width: inherit;
    margin: 0;
    padding: 0;
    padding-top: 20px;
    padding-bottom: 20px;
  }

  .noburger{
    display: none;
      }

.x1080 li{
font-size: larger;
margin-right: 0;
}
.x1080 li:nth-of-type(1){
  animation: move 0.6s ease-in-out;
}
.x1080 li:nth-of-type(2){
  animation: move 1s ease-in-out;
}
.x1080 li:nth-of-type(3){
  animation: move 1.5s ease-in-out;
}

@keyframes move{
from {
  opacity: 0.5;
transform: translateX(40px) ;
}to{
  opacity: 1;
  transform: translateX(0px) ;
}}

/* filter */
.filter{
  display: flex;
  grid-gap: 10px;
  direction: ltr; 
  position: sticky;
  top: 0;
  z-index: 1;
}

.mobile_explain{
  direction: rtl;
  display: flex;
  justify-content: center;
  padding: 10px;
  background-color: #2a2a72;
  background-image: linear-gradient(315deg, #2a2a72 0%, #009ffd 74%);
  width: 90%;
  margin: 0 auto;
  margin-top: 10px;
  margin-bottom: 10px;
  align-self: center;
  color: white;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.2), 0 3px 10px 0 rgba(0, 0, 0, 0.19);
  }

  .close-button {
    font-size: x-large;
    margin-right: 5px;
    cursor: pointer;
    color: white;
  }

  .mobile_explain h4{
  margin-top: 2px;
  margin-bottom: 0;
  }

.search-item{
  font-size: medium;
  vertical-align: top;
  width: fit-content;
}
input{
  width: -webkit-fill-available;
}

.select_type_mobile{
  width: -webkit-fill-available;
}

.checkbox_div{
  width: 45%;
}

input[type="range"] {
  width: 100%;
}

.searchbtn{
font-size: larger;
grid-column: span 2;
}

.on{
/* width: 140%; */
grid-column: unset;
}

.clearbtn{
/* width: 60%; */
justify-self: end;
}

.logo{
  justify-self: center;
  height: fit-content;
}

  nav img{
    height: 80px;
  }

  /* search main */
  .main{
    left: 50%;
    /* transform: translate(-50%, -50%); */
    width: 100%;
    height: 100%;
    background-color: aliceblue;
    position: absolute;
    z-index: 1;
    border-radius: 2%;
    border: none;
    display: flex;
    min-height: 350px;
    max-height: 700px;
    width: 100%;
    flex-direction: column-reverse;
    justify-content: flex-end;

    position: absolute;
    top: var(--top_space); /* Adjust the top value to match the desired distance below the navigation */
    transition: margin-top 0.3s;
    left: 50%;
    transform: translateX(-50%);
  }
  .hidemap{
    display: none;
  }

  .col-1{
    flex-basis: 90%;
  }

  .col-1 .ActiveMap{
    display: block;
  }

  .col-2{
    flex-basis: 100%;
  }

  .results > :last-child{
    margin-bottom: 120px;

  }

 .Map{
    flex-basis: min-content;
    overflow: initial;
  }
    input{
      /* prevents safari inputs */
          border-radius: 0;
          -webkit-border-radius:0px;
        }
        select{
        appearance: none;
        -webkit-appearance: none;
        font-size: medium;
        line-height: 1.5rem;
        }

    .checkbox-container {
      display: inline-block;
    }
      .show_filter{
      margin-top: 20px;
      margin-left: 10px;
      display: flex;
      position: absolute;
      border: none;
      background: none;
      padding: 20px;
      border-radius: 50%;
      background: linear-gradient(55deg, rgba(154,111,255,1) 0%, rgba(100,90,255,1) 50%, rgba(119,119,207,1) 100%);
      width: 30px;
      height: 30px;
      align-items: center;
      justify-content: center;
      }
      .arrow_up{
        border: solid white;
        border-width: 0 3px 3px 0;
        display: inline-block;
        padding: 6px;
      transform: rotate(45deg);
      -webkit-transform: rotate(45deg);
      }
      .arrow_down{
        border: solid white;
        border-width: 0 3px 3px 0;
        display: inline-block;
        padding: 6px;
        transform: rotate(-135deg);
        -webkit-transform: rotate(-135deg);
      }
      .closed_filter{
      display: none;
      }
      .map_or_list{
      display: flex;
      }
      .map_or_list button{
        flex-basis: 50%;
        text-align: center;
        padding: 10px;
        border: none;
        border-bottom: 2px solid rgb(100,90,255,1);
        background-color: white;
        font-size: larger;
        color: #c5c5c5;
      }
      .divider{
      width: 1px;
      background-color: lightslategray;
      }

      .map_or_list .active{
      color: #333;
      background-color: whitesmoke;
      box-shadow:inset 0 4px 16px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
      }
      .map_is_open{
        display: none;
      }
      .more{
      align-self: center;
      direction: rtl;
      padding: 10px;
      }
/* logged profile */
      .update_profile {
        position: absolute;
        width: 100%;
        max-height: unset;
        box-sizing: border-box;
        border: none;
        border-radius: 0;
        display: block;
        overflow: auto;
        height: 100%;
        position: absolute;
        top: var(--top_space); /* Adjust the top value to match the desired distance below the navigation */
        transition: margin-top 0.3s;
        left: 50%;
        transform: translateX(-50%);
      }
             
      .settings{
      padding:8px 8px 0px 8px;
      display: block;
      }
      .settings ul{
        display: flex;
        flex-direction: row-reverse;
        margin: unset;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        padding: 0;
        gap: 10px;
      }

      .settings ul li:last-child {
        margin-top: unset;
      }

      .settings li{
      margin-bottom: 0;
      padding: 5px;
      border-bottom: lightgray solid 1px;
      }
      .settings .chosen{
        color: #647dee;
        font-weight: 600;
        border-bottom: #647dee solid 2px;
        }
        
      .inputs{
        display: flex;
        flex-direction: column;
        gap: 10px;
        /* overflow-y: auto; */
        /* height: inherit; */
        /* height:-webkit-fill-available; */
        margin-bottom: 120px;
      }
      .inputs input{
        width: -webkit-fill-available;
        margin-bottom: 10px;

      appearance: none;
      -webkit-appearance: none;
      border: none;
      border-radius: 5px;

    background-color: hsl(0, 0%, 100%);
    border-color: hsl(0, 0%, 80%);
    border-radius: 4px;
    border-style: solid;
    border-width: 1px;
    padding: 6px;
      }
    .AUTOCOMPLETE{
      width: -webkit-fill-available;
    }
      .v_line{
        display: none;
      }
      .update_profile .upload_file{
        align-self: center;
      }
    .map-container .trainer_box{
      bottom:22%;
      width: 85%; 
      }
      .trainer_box{
      /* height: 100px; */
      width: 100%;
      border-radius: 0;
      border: none;  

      }
    .trainer_box ul{
      justify-self: auto;
      text-align: right;
    }
    .trainer_box li{
      overflow: hidden;
    }
    .notfound{
      height: 100vh;
    }

    .p_web{
      overflow:unset ;
    }

  .p_web .box{
    height: 100px;
    max-width: 130px;
  }
  /* personal website */
    .bod{
      top: 0;
      height: unset;
      overflow-x: hidden;
      position: absolute;
      transform: translate(-50%);
    }
    .bod .img_container{
      width: 120%;
      max-height:250px ;
      border: var(--border-color) 8px solid;
      border-top: none;
      border-radius: 0 0 50% 50%;
    }

    .bod .img_container .back_img{
      border-radius: 0 0 50% 50%;
    }
    .bod .circle_p {
      height: 150px;
      width: 150px;
      margin-top: 30%;
/* 
    top: 50%;
    transform: translate(0%, -50%);
    height: 300px;
    width: -webkit-fill-available;
    border-radius: 0 0 50% 50%;
    overflow: hidden; */
    }

    /* .bod .circle_p img{
    width: -webkit-fill-available;
    object-fit: contain;
    object-position: center;
    position: absolute;
    width: 100%;
    object-fit: cover;
    } */

    .bod .Trainer_name{
      width: 80%;
      margin-top: 20%;
    }
    .bod .trainerdesc{
      width: 80%;
    }
    .bod h2{
    width: 80%;
    }
    .bod .training_types{
      width: 80%;
    }
    .bod .uls{
      width: 90%;
    }
    .bod li .image-container img {
      height: 40px;
      width: 40px;
    }
    
    /* about us */
    .mid{
      position: absolute;
      width: 100%;
      max-height: unset;
      box-sizing: border-box;
      border: none;
      border-radius: 0;
      display: block;
      overflow: auto;
      height: 100%;
      position: absolute;
      top:var(--top_space);
      transition: margin-top 0.3s;
      left: 50%;
      transform: translateX(-50%);
    }
    .about_h1{
      flex-direction: column-reverse;
      float: none;
      margin: 10px;
      margin-top: 30px;
      margin-bottom: 0;
      font-size: large;
    }
    .about_h2{
      float: none;
      text-align: center;
      padding-left: 20px;
      padding-right: 20px;
    }
    .about_h3{
    padding-bottom: 50px;
    padding-left: 20px;
    padding-right: 20px;
    }
      /* footer */
      .foot p{
        font-size: x-small;
        }
        .foot ul:hover{
          display: none;
        }  
        
  /* blog */
  .blog{
    left: 50%;
    /* transform: translate(-50%, -50%); */
    width: 100%;
    height: 100%;
    background-color: aliceblue;
    position: absolute;
    z-index: 1;
    border-radius: 2%;
    border: none;
    display: flex;
    min-height: 350px;
    max-height: 700px;
    width: 100%;
    flex-direction: column;
    position: absolute;
    top: var(--top_space);
    transition: margin-top 0.3s;
    left: 50%;
    transform: translateX(-50%);
  }
  .blog_list {
    margin-top: 0;
    margin-bottom: 0;
  }
  /* trainer first signin wizard */
  .SignUp_wizard{
    padding: 15px;
  }
  .SignUp_wizard input{
    margin-left: 0;
  }
  /* calendar */
  .calendar select{
    /* appearance:auto;
    -webkit-appearance: auto; */
  }
}
