.calendar {
    padding-left: 5px;
    padding-right: 5px;
    margin: 0 auto;
    text-align: center;
  }

  .header {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 10px;
    margin-top: 20px;
  }
  
  .header select, .times_head select {
    border: none;
    width: 100px;
    font-size: x-large;
    font-weight: 600;
    color: black;
    appearance:none;
    -webkit-appearance:none; 
    margin: 0;
  }
  
  .header select:focus {
    outline: none;
    color: #333;
  }
  
  .header select option:disabled {
    color: #ccc;
  }

  .select-wrapper {
    position: relative;
    display: flex;
    align-items: center;
    margin-bottom: 5px;
  }
  
  .select-wrapper img {
    height: 20px;
    transition: transform 0.3s ease;
  }
  
  .select-wrapper select:focus + img {
    opacity: 0.5;
    transform: rotate(180deg);
  }
  
  .times_head select.select-duration{
    background-color: rgba(211, 211, 211, 0.604);
    text-align: center;
    width: fit-content;
  }

  .select-dropdown {
    position: absolute;
    top: 100%;
    right: 0;
    background-color: #fff;
    border: 1px solid #ccc;
    padding: 4px;
    border-radius: 4px;
    z-index: 1;
  }
  
  .week-dates {
    display: flex;
    justify-content: space-around;
    transition: transform 0.5s ease-out;

    position: sticky;
    top: 0;
    z-index: 100;
    background-color: white;
    margin-top: 5px;
    border-bottom: #3333332e 1px solid;
  }
  
  .week-dates.swipe-left {
    transform: translateX(-100%);
  }
  
  .week-dates.swipe-right {
    transform: translateX(100%);
  }

  .select-wrapper h2{
    margin-top: 0;
    margin-bottom: 0;
    margin-left: 10px;
    font-size: large;
  }

  .times_head{
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin-bottom: 10px;
    margin-top: 10px;
  }
  
  .times_head h2{
    font-size: large;
  }

  .date {
    display: flex;
    flex-direction: column;
    align-items: center;
    direction: rtl;
    font-family: Arial, sans-serif;
    padding: 5px;
  }
  
  .date .day-date {
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 5px;
    font-size: large;
    transition: background-color 0.3s ease;
  }
  
  .selected .day-date {
    color: white;
    border-radius: 50%;
    background: linear-gradient(
      55deg,
      rgba(154, 111, 255, 1) 0%,
      rgba(100, 90, 255, 1) 50%,
      rgba(119, 119, 207, 1) 100%
    );
    font-weight: 600;
  }
  
  .day-name {
    color: #ccc;
  }
  
  .time-slots-container {
    border: 1px solid #ccc;
    border-left: none;
    border-right: none;
    border-bottom: none;
    padding: 10px;
    max-height:300px;
    overflow-y: scroll;
    margin-bottom: 30px;
  }

  .time-slots {
    padding: 10px;
    padding-top: 0;
  }
  
  .time-slot-item {
    padding: 5px;
    height:fit-content;
    border-bottom: 1px solid #ccc;
    display: flex;
    flex-direction: column;
  }

  .time-slot-item.selected {
    background-image: linear-gradient(315deg,#7f53ac,#647dee 74%);
    color: white;
    border-radius: 15px;
    border: none;
    margin-top: 10px;
    padding: 10px;
  }

  .time-slot-item.selected + .time-slot-item {
    margin-top: 10px;
    border-top: 1px solid #ccc;
  }

  .time-slot-item h2{
    text-align: right;
    margin-top: 5px;
    margin-bottom: 0;
    font-weight: 600;
  }

  .time-slot-item .hours{
    display: flex;
    justify-content: space-between;
    max-width: 45%;
    align-items: center;
    gap: 15px;
  }
  .time-slot-item .hours h3{
    margin-top: 0;
    margin-bottom: 0;
  }
  
  /* Style the scrollbar */
  .time-slots::-webkit-scrollbar {
    width: 8px;
  }
  
  .time-slots::-webkit-scrollbar-thumb {
    background-color: #888;
  }
  
  .time-slots::-webkit-scrollbar-track {
    background-color: #f1f1f1;
  }

  /* .btn_div{
    bottom: 18%;
    position: fixed;
    width: 100%;
    background-color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 80px;
  } */

  .new_training{
    background-color: #7f53ac;
    background-image: linear-gradient(315deg,#7f53ac,#647dee 74%);
    border: 1px solid #647dee;
    border-radius: 5px;
    box-shadow: 0 4px 8px 0 rgba(0,0,0,.1), 0 3px 10px 0 rgba(0,0,0,.1);
    color: #fff;
    cursor: pointer;
    font-family: Heebo,sans-serif;
    font-weight: 500;
    font-size: large;
    outline: none;
    padding: 5px;
    text-align: center;
    width: 80%;
    height: fit-content;
  }
  
  