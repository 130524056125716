/* hebrew */
@font-face {
  font-family: 'Heebo';
  font-style: normal;
  font-weight: 400;
  src: url(https://fonts.gstatic.com/s/heebo/v21/NGS6v5_NC0k9P9H0TbFzsQ.woff2) format('woff2');
  unicode-range: U+0590-05FF, U+200C-2010, U+20AA, U+25CC, U+FB1D-FB4F;
  font-display: swap;
}

@font-face {
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: 400;
  src: url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;500;600&display=swap');
  unicode-range: U+30-39;
}

:lang(he) {
  font-family: 'Heebo', sans-serif;
  font-weight: 400;
  /* text-align: right; */
  font-style: normal;
}

:lang(en) {
  font-family: 'Poppins', sans-serif;
}

select{
  padding:2px 5px;
}

body{
  overflow-x: hidden;
  overflow-y: auto;
  margin: 0;
  padding: 0;
}
.confetti{
  position: absolute;
  z-index: 1;
  opacity: 1;
}
