
.admin_panel{
    display: flex;
    justify-content:flex-start;
    align-items: center;
    flex-direction: row-reverse;
    max-width: 1080px;
    margin: auto;
    margin-top: 8%;
    overflow: auto;
    border-radius: 15px;
}

/* menu */
.admin_panel .admin_menu {
    width: 20%; /* Adjust as needed */
    height: 600px;
    background-color: white;
    border: 1px solid hsl(0, 0%, 80%);
    border-radius: 15px;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border-left: none;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1), 0 3px 6px 0 rgba(0, 0, 0, 0.1);
  }

  .admin_menu .menu_options{
      list-style: none;
      height: fit-content;
      width:80%;
      padding:10%;
      margin: 0;
      }

    .menu_options li{
        margin-top: 20px;
        text-align: right;
        cursor: pointer;
        margin-bottom: 30px;
        }
        .menu_options .open{
            background-color: #4318ff;
            border-radius: 8px;
            padding: 6px;
            box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1), 0 3px 6px 0 rgba(0, 0, 0, 0.1);
        }
        .menu_options .open span{
            color: white;
        }
        .menu_options .open img{
            filter: brightness(0) invert(1);
            opacity: 1;
        }
    .menu_options li img{
        height: 20px;
        margin-left: 15px;
        opacity: 0.5;
        padding-right: 10px;
    }
    .menu_options li span{
        color: lightgray;
        }
    

/* panel */
.admin_panel .admin_main_view{
    width: 100%; /* Adjust as needed */
    height: 600px;
    background-color: whitesmoke;
    border: 1px solid hsl(0, 0%, 80%);
    border-radius: 15px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-right: none;
  }

.admin_main_view .view{
padding: 50px;
display: flex;
flex-direction: row-reverse;
}

.admin_main_view .view .times{
    position: absolute;
}
.admin_main_view .view .times .time_btn{
    padding: 5px;
    padding-left: 10px;
    padding-right: 10px;
    appearance: none;
    cursor: pointer;
    background-color: white;
    border: #333 solid 1px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1), 0 3px 6px 0 rgba(0, 0, 0, 0.1);
    color: #c5c5c5;
}

.admin_main_view .view .times .time_class{
    color: #333;
    box-shadow: inset 0 4px 16px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.admin_main_view .view .times button:hover{
background-color: lightgray;
}

.admin_main_view .view .times button:first-child{
border-top-left-radius: 10px;
border-bottom-left-radius: 10px;
}
.admin_main_view .view .times button:last-child{
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    }

.admin_main_view .view .first_row{
    display: flex;
    margin-top: 80px;
    justify-content: space-between;
    width: 100%;
}

.admin_main_view .view .data{
text-align: center;
background-color: white;
border-radius: 15px;
padding: 15px;
box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1), 0 3px 10px 0 rgba(0, 0, 0, 0.1);
height: 100px;
width: 130px;
}

.data h3{
  font-weight: 600;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
}

.admin_main_view .view .data span{
    color: #333;
}

.admin_main_view .view .data img{
    height:20px;
    vertical-align: middle;
    position: relative;
    left: 40%;
    filter: brightness(50%);
    opacity: 0.5;
}
/* Ussrs*/
.admin_panel .users{
padding: 45px;
padding-bottom: 30px;
position: relative;
height: 85%;
}

.users h3{
margin-top: 0;
}
.users .users_table {
    direction: rtl;
    width: 100%;
    border-collapse: collapse;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1), 0 3px 6px 0 rgba(0, 0, 0, 0.1);
  }
  
  .users thead{
    background-color: #5f5b5b;
  }

  .users  .users_table th,
  .users  .users_table td {
    padding: 10px;
    text-align: right;
    min-width: 20px;
  }
  
  .users  .users_table th {
    font-weight: bold;
  }
  
  .users .users_table tbody tr:nth-child(even) {
    background-color: #cfcece;
  }
  .users .users_table tbody tr:nth-child(odd) {
    background-color: #aaa8a8;
  }
  .users .users_table tbody tr.present_false {
    background-color: #ff7272;
  }

 .users_table td button{
    width: 50px;
    cursor: pointer;
  }

  .users .users_table .circle{
    height: 50px;
    width: 50px;
  }

  .users .btns{
    position: absolute;
    margin-top: 20px;
    bottom: 0;
    display: flex;
    align-items: end;
  }

  .users .btns button{
    margin: 10px;
  }
  .users .page-counter {
    text-align: right;
    margin-bottom: 10px;
  }
  
  .users .trainer-count {
    text-align: right;
  }