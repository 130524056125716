@import url('https://fonts.googleapis.com/css2?family=Exo:wght@700&display=swap&font-display=swap');

.foot {
  bottom: 0;
  display: flex;
  background-color: #333;
  width: 100%;
  justify-content: center;
  position: fixed;
  bottom: 0;
  z-index: 1;
  flex-direction: column;
  align-items: center;
}

.foot p {
  font-family: 'Exo', sans-serif;
  color: white;
  justify-self: center;
  align-self: center;
  font-style: italic;
}

.foot ul {
  display: none;
}

.foot:hover ul {
  display: flex;
  list-style: none;
  padding: 0;
  margin: 0;
  padding-bottom: 10px;
}

.foot ul li {
  color: rgb(205, 205, 205);
  margin: 0 10px;
  font-size: small;
}

.foot a {
  color: rgb(205, 205, 205);
  font-size: small;
}
