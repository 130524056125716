.tooltip-container {
    position: relative;
    display: inline-block;
    cursor: pointer;
  }
  
  .tooltip-text {
    height: 30px;
    width: 30px;
    left: 0;
    position: absolute;
    margin-left: 10px;
    margin-top: 10px;
  }
  
  .tooltip-message {
    visibility: visible;
    background-color: #555;
    color: #fff;
    text-align: center;
    padding: 5px 10px;
    border-radius: 6px;
    position: absolute;
    z-index: 1;
    top: 0;
    opacity: 1;
    transition: opacity 0.3s;
    height: fit-content;
    width: -webkit-fill-available;
    font-size: large;
  }
  
  .tooltip-message .close-button {
    all: initial;
    background: none;
    color: #fff;
    border: none;
    position: absolute;
    top: 0;
    left: 0;
    font-size: 16px;
    line-height: 1;
    padding: 5px;
    cursor: pointer;
    font-size: large;
  }
  
  .tooltip-message .close-button:focus {
    outline: none;
  }
  