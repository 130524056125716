.SignUp_wizard{
width: 85%;
margin: 0 auto;
background: white;
margin-top: 20px;
display: grid;
box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1), 0 3px 10px 0 rgba(0, 0, 0, 0.1);
border-radius: 15px;
margin-bottom: 50px;
padding: 50px;
margin-top: 30px;
}

.SignUp_wizard h1{
font-weight: 600;
color: #414447;
font-size: x-large;
text-align: center;
margin: 0;
margin-bottom: 20px;
}

.SignUp_wizard .steps{
display: flex;
justify-content: space-around;
align-items: center;
height: 100px;
margin-top: 30px;
}

.SignUp_wizard .steps .steps_div{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: min-content;
}

.SignUp_wizard .sign_step{
    border-radius: 50%;
    background-color: rgb(255, 255, 255);
    width: 40px;
    height: 40px;
    justify-self: center;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    border: #333 1px solid;
    opacity: 0.5;
}

.SignUp_wizard .current_step{
    background: linear-gradient(55deg, rgba(154,111,255,1) 0%, rgba(100,90,255,1) 50%, rgba(119,119,207,1) 100%);
    opacity: 1;
    border: rgba(51, 51, 51, 0.5) 1px solid;
    /* margin-bottom: 20px; */
    margin-bottom: 30px;
}
.SignUp_wizard .sign_step span{
font-weight: 400;
}
.SignUp_wizard .current_step span{
    font-weight: 600;
    color: white;
}

.SignUp_wizard .step_line{
background-color: #333;
height: 1px;
width: 8%;
opacity: 0.4;
flex: auto;
}
.SignUp_wizard .circle{
    float: none;
}
.SignUp_wizard span{
    color: #333;
    font-weight: 600;
    text-align: center;
    width: max-content;
}

.SignUp_wizard input{
appearance: none;
border-radius: 5px;
margin-bottom: 30px;
line-height: 2em;
background-color: #fff;
border: 1px solid #ccc;
font-size: medium;
margin-left: 20px;
}

.SignUp_wizard input.insta{
width: -webkit-fill-available;
}

.SignUp_wizard span.required{
    color: red;
    margin-left: 30px;
}

.SignUp_wizard textarea{
margin-top: 10px;
width: -webkit-fill-available;
min-height: 100px;
max-height: fit-content;
font-size: medium;
}

.SignUp_wizard .train_kind{
display: flex;
margin-top: 20px;
}

.SignUp_wizard .btns{
    display: flex;
    justify-content: space-evenly;
    margin-top: 20px;
}

.SignUp_wizard .btn_back{
    background-color: #fff;
    border: 1px solid #414447;
    color: #414447;
    padding: 5px 20px;
    cursor: pointer;
    font-weight: 500;
    outline: none;
    text-align: center;
    border-radius: 5px;
    /* box-shadow: 0 4px 8px 0 rgba(0,0,0,.1), 0 3px 10px 0 rgba(0,0,0,.1); */
    font-size: medium;
}

.SignUp_wizard .btn_next{
    background: linear-gradient(55deg, rgba(154,111,255,1) 0%, rgba(100,90,255,1) 50%, rgba(119,119,207,1) 100%);
    border: none;
    color: white;
    padding: 5px 20px;
    cursor: pointer;
    font-weight: 500;
    outline: none;
    text-align: center;
    border-radius: 5px;
    /* box-shadow: 0 4px 8px 0 rgba(0,0,0,.1), 0 3px 10px 0 rgba(0,0,0,.1); */
     font-size: medium;
}

.SignUp_wizard .submit_button{
    background: linear-gradient(55deg, rgba(154,111,255,1) 0%, rgba(100,90,255,1) 50%, rgba(119,119,207,1) 100%);
    border: none;
    color: white;
    padding: 5px 20px;
    cursor: pointer;
    font-weight: 500;
    outline: none;
    text-align: center;
    border-radius: 5px;
    /* box-shadow: 0 4px 8px 0 rgba(0,0,0,.1), 0 3px 10px 0 rgba(0,0,0,.1); */
    font-size: medium;
}

.submit_button img{
height: 30px;
}

.SignUp_wizard h2{
color: #333;
font-size: medium;
font-weight: 600;
float: unset;
}

.SignUp_wizard .upload_file{
margin-top: 20px;
display: inline-grid;
}

